import { db, fbStorage } from '../firebaseDb'
import Patient, { patientConverter } from './Patient';
import UserModel, { userConverter } from './UserModel';

/**
 * MedDoc class
 */
export class MedDoc {

    /**
     * @constructor
     * @param {string} dateIssued 
     * @param {firebase.firestore.DocumentReference} doctor 
     * @param {firebase.firestore.DocumentReference} doctype 
     * @param {firebase.firestore.DocumentReference} patient 
     * @param {string} enteredBy 
     * @param {string} textData 
     * @param {string} dateAdded 
     * @param {array<string>} cloudFiles
     */
    constructor(dateIssued, doctor, doctype, patient, cloudFiles = [], enteredBy=null, textData=null, dateAdded=null, id) {
        this.dateIssued = dateIssued;
        this.doctor = doctor;
        this.doctype = doctype;
        this.patient = patient;
        this.textData = textData; 
        this.enteredBy = enteredBy;
        this.dateAdded = dateAdded;
        this.cloudFiles = cloudFiles;
        this.DoctorObj = { name: '', id: ''}
        this.DoctypeObj = { name: '', id: ''}
        this.PatientObj = new Patient('', '', '', '', 0)
        this.EnteredByObj = new UserModel()
        this.id = id
    }


    /**
     * 
     * @return {MedDoc}
     */
    async LoadAllFields() {
        
        let toDos = [
            this.GetDoctor(),
            this.GetDoctype(),
            this.GetPatient(),
            this.GetUser()
        ]
        
        let [doctor, doctype, patient, user] = await Promise.all(toDos)
        let refs = this.GetFileRefs();
        
        this.urls = [];
        this.DoctorObj = doctor;
        this.DoctypeObj = doctype;
        this.PatientObj = patient;
        this.EnteredByObj = user;

        let urlTodos = [];
        refs.forEach(r => { urlTodos.push( r.getDownloadURL() ) })
        let urls = await Promise.all(urlTodos)
        this.urls = urls;

        return this; 
    }

   
    /**
     * 
     * @return {Promise<[MedDoc]>} Array of all patients
     */
    static fetchAllData() {
        return new Promise( (resolve, reject) => {
            let collection = db.collection('meddoc').withConverter(medDocConv)
            collection.get().then( (snapshot) => {
                let ds =  snapshot.docs.map( (doc)=>doc.data() )
                resolve(ds)
            } ).catch( (err) => {
                reject(err)
            } )
        } )
        
        
    }


    /**
     * 
     * @param {Patient} patient 
     * @return {Promise<[MedDoc]>}
     */
    static fetchForPatient(patient) {
        return new Promise( (resolve,reject) => {
            let collection = db.collection('meddoc').withConverter(medDocConv)
            let patientRef = db.collection('patient').doc(patient.id);
            collection.where('patient', '==', patientRef).get().then( (snap)=>{
                resolve(snap.docs.map( (d) => d.data() ))
            } ).catch( (err)=>{
                reject(err)
            })
        } )
    }

    static async fetchLimitedData(limit) {
        let collection = db.collection('meddoc').withConverter(medDocConv).limit(limit)
        let snapshot = await collection.get()
        let docs =  snapshot.docs.map( (doc)=>doc.data() )
        return docs
    }

    /**
     * 
     * @param {String} id 
     * @returns {MedDoc}
     */
    static async GetById(id) {
        let doc = db.collection('meddoc').withConverter(medDocConv).doc(id)
        let snap = await doc.get();
        return snap.data();
    }

    /**
     * 
     * @return {Promise<Patient>}
     */
    GetPatient() {
        return new Promise( (resolve, reject) => {
            this.patient.withConverter(patientConverter).get().then( doc => resolve( doc.data() ) ).catch( err => reject(err) )
        } );
    }

    /**
     * 
     * @returns Promise<{name:string}>
     */
    GetDoctor() {
        return new Promise( (resolve, reject) => {
            this.doctor.get().then( doc => resolve( {name: doc.data().name, id: doc.id} ) ).catch( err => reject(err) )
        } );
    }


    /**
     * 
     * @return {[firebase.storege.Reference]}
     */
    GetFileRefs() {
        let refs = [];
        this.cloudFiles.forEach( s => {
            refs.push(fbStorage.ref(s))
        } )
        return refs;
    }


    get Doctor() {
        this.GetDoctor().then( doc => { this.doctorHelper = doc } );
        return this.doctorHelper;
    }

    get IssuedDateString() {
        let d = new Date(this.dateIssued.seconds * 1000);
        return d.toLocaleDateString();
    }

    get EnteredDateString() {
        let d = new Date(this.dateAdded.seconds * 1000);
        return d.toLocaleDateString();
    }

    /**
     * 
     * @returns {Promise<{id:string, name: string}>}
     */
    GetDoctype() {

        return new Promise( (resolve, reject) => {
            this.doctype.get().then( doc => resolve( {name: doc.data().name, id: doc.id} ) ).catch( err => reject(err) )
        } );
    }

    /**
     * 
     * @returns Promise<User>
     */
    GetUser() {
        return new Promise( (resolve, reject) => {
            this.enteredBy.withConverter(userConverter).get().then( doc => resolve( doc.data() ) ).catch( err => reject(err) )
        } );
    }

    /**
     * 
     * @param {Patient} pObj 
     */
    SetPatient(pObj) {
        this.patient = "/patient/" + pObj.id;
    } 

    /**
     * 
     * @param {User} uObj 
     */
     SetUser(uObj) {
        this.user = "/users/" + uObj.id;
    } 

    /**
     * 
     * @param {string} doctorId 
     */
    SetDoctor(doctorId) {
        this.doctor = "/doctor/" + doctorId;
    } 

    /**
     * 
     * @param {string} doctypeId 
     */
     SetDoctype(doctypeId) {
        this.doctype = "/doctype/" + doctypeId;
    } 

    /**
     * 
     * @returns {Promise<void>}
     */
    remove() {
        return new Promise( (resolve, reject) => {
            let fRefs = this.GetFileRefs();
            if (fRefs.length > 0) {
                let delProms = [];
                fRefs.forEach( (fRef) => { delProms.push(fRef.delete()) } )
                Promise.all(delProms).then ( () => {
                    db.collection('meddoc').doc(this.id).delete().then( () => {
                        resolve()
                    } ).catch ( err => { reject(err) } )
                } ).catch ( err => { reject(err) } )
            } else {
                db.collection('meddoc').doc(this.id).delete().then( () => { 
                    resolve() 
                } ).catch( (err) => { reject(err) })
            } 
        } )
    }

    /**
     * 
     * @returns Promise<firebase.firestore.DocumentReference<MedDoc>>
     */
    create() {
        return db.collection('meddoc').withConverter(medDocConv).add(this);
    }
}

export const medDocConv = {
    /**
     * 
     * @param {MedDoc} medDoc 
     * @returns any
     */
    toFirestore: (medDoc) => {
        return {
            dateIssued: medDoc.dateIssued,
            doctor: medDoc.doctor,
            doctype: medDoc.doctype,
            patient: medDoc.patient,
            textData: medDoc.textData,
            enteredBy: medDoc.enteredBy,
            dateAdded: medDoc.dateAdded,
            cloudFiles: medDoc.cloudFiles
        }
    },

    /**
     * 
     * @param {firebase.firestore.QueryDocumentSnapshot} snapshot 
     * @param {firebase.firestore.SnapshotOptions} options 
     * @returns {MedDoc}
     */
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options)
        return new MedDoc(data.dateIssued, data.doctor, data.doctype, data.patient, data.cloudFiles, data.enteredBy, data.textData, data.dateAdded, snapshot.id);
    }
}