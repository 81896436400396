<template>
    <div class="users-edit">
        <div class="row">
            <div class="col-12">
                <div v-if=" GlobalWarning != '' " class="alert alert-danger">
                    <span>{{ GlobalWarning }}</span>
                </div>  
            </div>
        </div>
        <Card v-bind:HeaderText="this.GetHeaderText()" SubheaderText="Edycja danych uzytkownika">
            <form v-on:submit.prevent="onSubmit">
                <div class="row mt-4">
                    <div class="col-md-3">
                        <div class="form-group bmd-form-group is-filled">
                          <label class="bmd-label-floating">Imię</label>
                          <input v-model="this.User.firstName" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group bmd-form-group is-filled">
                          <label class="bmd-label-floating">Nazwisko</label>
                          <input v-model="this.User.lastName" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group bmd-form-group is-filled">
                          <label class="bmd-label-floating">E-mail</label>
                          <input v-model="this.User.email" type="mail" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <button style="width:95%" :disabled="ChangePassword" v-if="IsDeleteVisible" v-on:click="ChangePassword=true" class="btn btn-success">Zmień hasło</button>
                    </div>
                </div>
                <div class="row mt-4" v-if="ChangePassword">
                    <div class="col-md-12">
                            <hr/>
                            <h6>Zmiana hasła</h6>
                        

                        <div v-if=" WarningText != '' " class="alert alert-warning">
                            <span>{{ WarningText }}</span>
                        </div>  
                    </div>
                    <div class="col-md-6">
                        <div class="form-group bmd-form-group is-filled">
                          <label class="bmd-label-floating">Hasło</label>
                          <input type="password" class="form-control" v-model="Password">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group bmd-form-group is-filled">
                          <label class="bmd-label-floating">Powtórz hasło</label>
                          <input type="password" class="form-control" v-model="PasswordRepeat">
                        </div>
                    </div>
                </div>
                <div class="row mt-4" v-if="this.CanUpdateRole">
                    <div class="col-md-12">
                        <div class="form-group bmd-form-group is-filled ml-1">
                            <label class="bmd-label-floating">Poziom dostępu</label>
                            <div class="container">
                                <div class="row">
                                    <div class="form-check form-check-radio col-md-3">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="radio" name="adminLvl" id="adminLvl-0" value="0" v-model="this.User.adminLvl" >
                                            zablokowany
                                            <span class="circle">
                                                <span class="check"></span>
                                            </span>
                                        </label>
                                    </div>

                                    <div class="form-check form-check-radio col-md-3">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="radio" name="adminLvl" id="adminLvl-1" value="1" v-model="this.User.adminLvl" >
                                            uzytkownik
                                            <span class="circle">
                                                <span class="check"></span>
                                            </span>
                                        </label>
                                    </div>

                                    <div class="form-check form-check-radio col-md-3">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="radio" name="adminLvl" id="adminLvl-2" value="2" v-model="this.User.adminLvl" >
                                            kierownik
                                            <span class="circle">
                                                <span class="check"></span>
                                            </span>
                                        </label>
                                    </div>
                                    <div class="form-check form-check-radio col-md-3">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="radio" name="adminLvl" id="adminLvl-3" value="3" v-model="this.User.adminLvl" >
                                            administrator
                                            <span class="circle">
                                                <span class="check"></span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12"><hr/></div>
                    <div class="col-12">
                        <button v-on:click="SaveData" class="btn btn-success pull-right">
                            <span class="material-icons mr-2">done</span>
                            zapisz
                        </button>
                        <button v-if="IsDeleteVisible" v-on:click="RemoveUser" class="btn btn-danger">
                            <span class="material-icons mr-2">delete</span>
                            usuń
                        </button>
                    </div>
                </div>
            </form>
        </Card>
    </div>
</template>

<script>
import Card from '@/components/Card.vue'
import UserModel from '../model/UserModel'
import { getAuth, updateEmail, updatePassword } from "firebase/auth";
var md5 = require('md5');



export default {
    name: 'UsersEdit',
    components: { Card },

    methods: {
       GetHeaderText() {
           return this.User.firstName + " " + this.User.lastName;
       },

       async SaveData() {
           let validated = false;
           const auth = getAuth()
           if ( (this.User.firstName != '') && (this.User.lastName != '') && (this.User.email != '') && (this.WarningText == '') ) validated = true;
           if (validated == true) {
                if (this.Password != '')
                this.User.password = md5(this.Password);
                this.User.adminLvl = parseInt(this.User.adminLvl);
                this.GlobalWarning = "";
                await UserModel.Update(this.UserId, this.User);

                updateEmail(auth.currentUser, this.User.email).then( ()=>{
                    updatePassword(auth.currentUser, this.User.password).then( ()=> {
                        this.$router.push( { path: '/users' } );    
                    }).catch( (err) => {
                        this.GlobalWarning += err
                    } )
                         
                } ).catch((err) => {
                    this.GlobalWarning += err
                })

            } else {
               this.GlobalWarning = "Nie mozna zapisać danych. Sprawdź poprawność wprowadzonych informacji."
           }
       },

       async RemoveUser() {
           let uuid = getAuth().currentUser.uid;
           if (uuid == this.User.uuid) {
                var r = confirm("Czy na pewno chcesz usunąć uzytkownika?");
                if (r == true) {
                    UserModel.GetByUUID(uuid, async (usrDb)=>{
                        await UserModel.RemoveById(usrDb.id)
                        await getAuth().currentUser.delete();
                        this.$router.push( { path: '/users' } );
                    });
                }
           } else {
               this.GlobalWarning = "Niedozwolona operacja"
           }
       }
    },

    computed: {
        WarningText: function() {
            if (this.Password == '') return ""
            else if (this.Password.length < 8) return "Podane hasło jest za krótkie."
            else if (this.Password != this.PasswordRepeat) return "Podane hasła róznią się."
            else return ""
        },

        IsDeleteVisible: function() {
            let uuid = getAuth().currentUser.uid;
            if (uuid == this.User.uuid) return true;
            else return false
        },

        CanUpdateRole: function () {
            if (UserModel.LoggedUser.adminLvl >= 3) return true
            else return false;
        }
    },

    async created() {
        this.UserId = this.$route.params.id;
        if (this.$route.params.id != undefined) {
            this.User = await UserModel.GetById(this.$route.params.id);
            this.IsNewUser = false;
        }
        document.querySelector(".users-edit input").focus();
    },

    data() {
        return { 
            User: { lastName:"", firstName: "" }, 
            UserId: 0,
            ChangePassword: false,
            Password: "",
            PasswordRepeat: "",
            GlobalWarning: "",
            IsNewUser: true
        }
    }
}

</script>