import { db, fbFunctions } from '../firebaseDb'
import { collection, query, where, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";




export default class UserModel {
    /**
     * 
     * @param {int} adminLvl 
     * @returns {string} Description of the level
     */
    static GetAdminLvlDesc(adminLvl) {
        switch (adminLvl) {
            case 0: return "brak dostępu"
            case 1: return "uzytkownik"
            case 2: return "kierownik"
            case 3: return "administrator"
            default:
                break;
        }
    }


    /**
     * 
     * @returns {[UserModel]}
     */
    static async FetchAll() {
        let collection = db.collection('users');
        let snapshot = await collection.get();
        return snapshot.docs.map( a => { let obj = a.data(); obj.id = a.id; return obj } );
    }

    /**
     * 
     * @param {string} firstName 
     * @param {string} lastName 
     * @param {string} password 
     * @param {string} uuid 
     * @param {number} adminLvl 
     * @param {string} id 
     */
    constructor(firstName = '', lastName = '', password = '', uuid = null, adminLvl = 0, id = null) {
        this.firstName = firstName
        this.lastName = lastName
        this.password = password
        this.uuid = uuid
        this.adminLvl = adminLvl
        this.id = id
    }

    static async GetById(id) {
        let docRef = db.collection('users').doc(id);
        let doc = await docRef.get();
        return doc.data();
    }

    static async GetByUUID(UUID, cb) {
        const q = query(collection(db, "users"), where("uuid", "==", UUID));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.length == 0) return null;
        querySnapshot.forEach( (doc)=>{
            let id = doc.id
            let data = doc.data();
            data.id = id;
            cb(data);
        } )
    }

    static LoggedUser = { firstName:'', lastName:'', adminLvl: 0 }

    static GetLoggedUser(onSuccess, onFailure=null) {
        const auth = getAuth();
        auth.onAuthStateChanged( (fbUser) => {
            if (fbUser) {
                fbFunctions.httpsCallable('IsLoginLocationAllowed').call().then( async (res) => { 
                    if (res.data.allowed === true) {
                        if (fbUser == null) return null;
                        const q = query(collection(db, "users"), where("uuid", "==", fbUser.uid));
                        const querySnapshot = await getDocs(q);
                        if (querySnapshot.size == 0) return null;
                        let doc = querySnapshot.docs[0];
                        let id = doc.id
                        let data = doc.data();
                        data.id = id;
                        data.fbUser = fbUser;
                        UserModel.LoggedUser = data;
                        onSuccess(data);
                    } else {
                        window.alert("Do korzystania z serwisu wymagana jest konkretna lokalizacja uzytkownika określona w zasadach zabezpieczeń instytucji.")
                        onFailure();
                    }
                } ).catch( (err) => { onFailure(err) } );
            } else {
                onFailure();
            }
        });
    }

    static async RemoveById(id) {
        let docRef = db.collection('users').doc(id);
        return docRef.delete()
    }

    static async Update(UserId, dataFields) {
        let docRef = db.collection('users').doc(UserId);
        let updated = await docRef.update(dataFields);
        return updated
    }

    static async Create(UUID, dataFields) {
        dataFields.uuid = UUID;
        let docId = db.collection('users').add(dataFields)
        return docId
    }

    static async Authenticate(email, password, authToken='') {
        console.log(email);
        console.log(password);    
        console.log(authToken);
    }

    static async GetAuthenticatedUser(authToken) {
        let docRef = db.collection('users').doc({authToken: authToken});
        let doc = await docRef.get();
        return doc.data();
    }
}

export const userConverter = {

    /**
     * 
     * @param {UserModel} user 
     */
    toFirestore(user) {
        return {
            firstName: user.firstName,
            lastName: user.lastName,
            password: user.password,
            uuid: user.uuid,
            adminLvl: user.adminLvl
        }
    },

    /**
     * 
     * @param {firebase.firestore.QueryDocumentSnapshot} snapshot 
     * @param {firebase.firestore.SnapshotOptions} options 
     * @return {UserModel}
     */
     fromFirestore(snapshot, options) {
        const data = snapshot.data(options)
        return new UserModel(data.firstName, data.lastName, data.password, data.uuid, data.adminLvl, snapshot.id)
    }
}