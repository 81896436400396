<template>
    <div class="patients">
        <PatientNew v-if="this.ShowAddPatient"></PatientNew>

        <Card v-if="!this.ShowAddPatient" HeaderText="Pacjenci zarejestrowani w bazie" SubheaderText="Przeszukuj, sortuj i filtruj dane w widoku pacjentów." LinkText="<span class='material-icons'>add</span> Dodaj" :ActionTo="ShowAdd">
            <div class="table-responsive">
                <table class="table">
                    <thead class="text-success">
                        <tr>
                            <th>#</th>
                            <th>Imię i nazwisko</th>
                            <th>PESEL</th>
                            <th>E-mail</th>
                            <th>Nr tel.</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(patient,i) in Patients" :key="patient.id">
                            <td>{{i+1}}</td>
                            <td>{{patient.firstName}} {{patient.lastName}}</td>
                            <td>{{patient.pesel}}</td>
                            <td>{{patient.mail}}</td>
                            <td>{{patient.phonePresent()}}</td>
                            <td>
                                <router-link class="btn btn-info pull-right btn-sm" :to="{name: 'PatientShow', params: {id: patient.id} }">
                                    <span class="material-icons">arrow_forward_ios</span>
                                </router-link>
                                <!--<button v-if="CanDelete" v-on:click="RemovePatient(patient)" class="float-right btn btn-sm btn-outline-danger"><span class="material-icons">delete</span></button>-->
                            </td>
                        </tr>
                    </tbody>
                    <tfoot v-if="NumberOfRows>0">
                        <tr>
                            <td colspan="7" style="text-align:center; font-weight: bold; border-top: 1px solid #aaa;">Baza zawiera <b>{{NumberOfRows}}</b> rekordów.</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </Card>
    </div>
</template>

<script>
import Card from '@/components/Card.vue'
import PatientNew from '../views/PatientNew.vue'
import Patient from '../model/Patient'
import UserModel from '../model/UserModel';
//import AppVue from '../App.vue';
import { /*fbApp,*/ fbFunctions } from '../firebaseDb';

export default {
    name: 'Patients',
    components: { Card, PatientNew },


    methods: {
        /**
         * @param {Patient} p
         */
        RemovePatient(p) {
            var r = confirm("Czy na pewno chcesz usunąć pacjenta i wszystkie dokumenty do niego przypisane?");
            if (r == true) {
                p.remove().then( ()=>{ 
                    this.Patients = this.Patients.filter( pp => pp.id !== p.id )
                 } )
            }
        },

        ShowAdd() {
            this.ShowAddPatient = true;
        },

        async FetchData(val) {
            if  ( (val != null) && (val.length >= 3)) {
                this.Patients = await Patient.fetchQueryData(val)
            } else {
                this.Patients = await Patient.fetchLimitedData(25)
            }
        } 
    },

    watch: {
        QueryField: async function(val) {
            await this.FetchData(val);
        }
    },

    computed: {
       QueryField: function() { return this.$parent.$parent.QueryField },
       CanDelete: function () { return UserModel.LoggedUser.adminLvl >= 3 }
    },

    async created() {
        this.Patients = await Patient.fetchLimitedData(25)
        let getCounter = fbFunctions.httpsCallable('GetAllPatientsNumber');
        getCounter.call().then( (res) => { this.NumberOfRows = res.data.snapSize } );
    },

    data() {
        return { Patients: [], ShowAddPatient: false, NumberOfRows: 0 }
    }
    
}



</script>