import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Users from '../views/Users.vue'
import UsersEdit from '../views/UsersEdit.vue'
import UsersNew from '../views/UsersNew.vue'
import Login from '../views/Login.vue'
import UserModel from '../model/UserModel'
import Types from '../views/Types.vue'
import Patients from '../views/Patients.vue'
import PatientShow from '../views/PatientShow.vue'
import PatientNew from '../views/PatientNew.vue'


const routes = [
  { path: '/', component: Home, meta: { requireAdminLvl: 1 }  },
  { path: '/home', component: Home, meta: { requireAdminLvl: 1 } },
  { path: '/about', component: About, meta: { requireAdminLvl: 0 } },
  { path: '/users', component: Users, meta: { requireAdminLvl: 3 } },
  { path: '/users/edit/:id', component: UsersEdit, name: "UserEdit", meta: { requireAdminLvl: 1 } },
  { path: '/users/new', component: UsersNew, name: "UserNew", meta: { requireAdminLvl: 3 } },
  { path: '/login', component: Login, name: "Login", meta: { requireAdminLvl: 0 } },
  { path: '/types', component: Types, name: 'Types', meta: { requireAdminLvl: 1 } },
  { path: '/patients', component: Patients, name: 'Patients', meta: { requireAdminLvl: 1 } },
  { path: '/patients/:id', component: PatientShow, name: 'PatientShow', meta: { requireAdminLvl: 1 } },
  { path: '/patients/edit/:id', component: PatientNew, name: 'PatientEdit', meta: { requireAdminLvl: 1 } },
  { path: '/patients/:id/addDoc', component: PatientShow, name: 'PatientAddDoc', meta: { requireAdminLvl: 1 } },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requireAdminLvl) {
    let adminLvl = to.meta.requireAdminLvl
    let user = UserModel.LoggedUser;

    if (adminLvl == 0) { next() }
    else if (user.adminLvl == 0) {
      next({
        path: '/login'
      })
    } else if ( user.adminLvl < adminLvl ) { 
       next({
          path: '/home'
        }) } else { 
          next() 
        }
  } else {
    next()
  }
})



export default router
