<template>
    <div class="table-responsive">
        <table class="table table-striped table-hover">
            <thead class="text-success">
                <tr>
                    <th>#</th>
                    <th>Wystawiono</th>
                    <th>Poradnia</th>
                    <th>Rodzaj</th>
                    <th>Informacje o archiwizacji</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(doc, i) in Docs" :key="doc.id" class="doc-row">
                    <td>{{i+1}}</td>
                    <td>{{doc.IssuedDateString}}</td>
                    <td>{{doc.DoctorObj.name}}</td>
                    <td>{{doc.DoctypeObj.name}}</td> 
                    <td>
                        <span class="enteredBy">
                            <span v-if="doc.EnteredByObj" >{{doc.EnteredByObj.firstName}} {{doc.EnteredByObj.lastName}}</span>
                            <span v-if="!doc.EnteredByObj" >uzytkownik usunięty</span>
                            <span class="pull-right">{{doc.EnteredDateString}}</span>
                        </span>
                    </td>
                    <td>
                        <a href='#' v-if='HavePermissionsToRemove' v-on:click='RemoveDoc(doc)' class="btn btn-sm btn-danger pull-right ml-4"><span class="material-icons mr">clear</span></a>
                        <a v-for="url in doc.urls" v-bind:key="url" target="_blank" class="btn btn-success pull-right btn-sm ml-1" :href="url">
                            <span class="material-icons">image_search</span>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table> 
    </div>
</template>

<script>
import Patient from '../model/Patient'
import UserModel from '../model/UserModel'

export default {
    name: 'PatientDocuments',
    props: { patient: Patient },

    methods: {
    
        /**
         * @param {MedDoc} md
         */
        RemoveDoc(md) {
            var r = confirm("Czy na pewno chcesz usunąć wybrany dokument?");
            if (r == true) {
                md.remove().then( ()=>{ 
                    this.Docs = this.Docs.filter( d => d != md )
                 } ).catch( err => { console.log(err) } ) 
            }
        },

    },

    computed: {
        HavePermissionsToRemove: function () { return UserModel.LoggedUser.adminLvl >= 3 } 
    },

    watch: {
       
    },


    async created() {
        let docs = await this.Patient.getDocsForPatient()
        docs.forEach( async (d) => { 
            let fullObj = await d.LoadAllFields();
            this.Docs.push(fullObj); 
        } )
    },

    data() {
        return { 
            Patient: this.patient,
            Docs: []
        }
    }
}

</script>