<template>
    <div class="card">
        <div class="card-header card-header-success">
            <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <h4 class="card-title ">{{HeaderText}}</h4>
                    <p class="card-category">{{SubheaderText}}</p>
                </div>
                <div class="col-md-4" v-if="LinkBtn">
                    <router-link :to="LinkTo" class="btn btn-secondary pull-right" v-html="LinkText"></router-link>
                </div>
                <div class="col-md-4" v-if="ActionBtn">
                    <button v-on:click="ActionTo" class="btn btn-secondary pull-right" v-html="LinkText"></button>
                </div>
            </div>
            </div>
        </div>
        <div class="card-body">
            <div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Card',
    props: {
        HeaderText: String, 
        SubheaderText: String,
        LinkTo: String,
        LinkText: String,
        ActionTo: { type: Function },
    },
    
    data() {
        return {ActionBtn: false, LinkBtn: false}
    },

    created() {
        this.ActionBtn = this.ActionTo !== undefined;
        this.LinkBtn = this.LinkTo !== undefined;
    }
}
</script>