import { db } from '../firebaseDb'
import { MedDoc } from './MedDoc'
// import { collection, query, where, getDocs } from "firebase/firestore";

/**
 * Patient class
 */
export default class Patient {
    /**
     * 
     * @param {String} firstName Patient's first name 
     * @param {String} lastName Patient's last name
     * @param {String} mail E-mail address
     * @param {String} phone Phone number
     * @param {Number} pesel Personal Identyfication Number (9-digits)
     * @param {String} id An unique ID managed by Firebase
     */
    constructor(firstName, lastName, mail, phone, pesel, id = undefined) {
        this.firstName = firstName
        this.lastName = lastName
        this.mail = mail
        this.phone = phone
        this.pesel = pesel
        this.id = id
    }

    /**
     * 
     * @returns {String}
     */
    toString() {
        return this.lastName.toUpperCase() + " " + this.firstName;
    }

    phonePresent() {
        return this.phone.length == 9 ? "+48 " + this.phone.substr(0,3) + " " + this.phone.substr(3,3) + " " + this.phone.substr(6,3) : this.phone;
    }

    /**
     * 
     * @returns {[Patient]} Array of all patients
     */
    static async fetchAllData() {
        let collection = db.collection('patient').withConverter(patientConverter)
        let snapshot = await collection.get()
        let docs =  snapshot.docs.map( (doc)=>doc.data() )
        return docs
    }

    static async fetchLimitedData(limit) {
        let collection = db.collection('patient').withConverter(patientConverter).limit(limit)
        let snapshot = await collection.get()
        let docs =  snapshot.docs.map( (doc)=>doc.data() )
        return docs
    }

    /**
     * 
     * @param {String} id 
     * @returns Patient
     */
    static async GetById(id) {
        let doc = db.collection('patient').withConverter(patientConverter).doc(id)
        let snap = await doc.get();
        return snap.data();
    }

    update() {
        let obj = {
            firstName: this.firstName,
            lastName: this.lastName,
            pesel: this.pesel,
            phone: this.phone,
            mail: this.mail
        }
        return db.collection('patient').doc(this.id).update(obj);
    }

    /**
     * 
     * @returns {Promise<void>}
     */
    remove() {
        return db.collection('patient').doc(this.id).delete()
    }

    /**
     * 
     * @returns {Promise<firebase.firestore.DocumentReference<Patient>>
}
     */
    create() {
        return db.collection('patient').withConverter(patientConverter).add(this);
    }

    /**
     * 
     * @return {Promise<[MedDoc]>}
     */
    async getDocsForPatient() {
        return await MedDoc.fetchForPatient(this)
    }


    /**
     * 
     * @param {String} query 
     * @param {Number} limit
     * @returns {Promise<[Patient]>} Array of patients with matched cryteria
     */

    static searchByDocsTokens(query) {
        return new Promise( (resolve, reject) => {
            let queryLiterals = query.split(' ');
            let qlProcessed = [];
            queryLiterals.forEach ( ql => { 
                qlProcessed.push(ql.toLowerCase())
                qlProcessed.push(ql.toUpperCase())
                qlProcessed.push(ql.substr(0,1).toUpperCase() + ql.substr(1).toLowerCase());
             } )

             let pJobs = [];

             db.collection('meddoc').where('textData', 'array-contains-any', qlProcessed).get().then( snap => {
                 if (snap.empty === true) resolve([]);
                 else {
                    snap.docs.forEach( (doc) => {
                        pJobs.push(doc.data().patient.withConverter(patientConverter).get());
                    });
                    Promise.all(pJobs).then( (ps) => {
                        let data = ps.map( p => p.data() )
                        resolve(data);
                    } )
                 }
             } ).catch( err => { reject(err) } )
        } )
    }

    static fetchQueryData(query) {
        return new Promise( (resolve, reject) => {
            let collection = db.collection('patient').withConverter(patientConverter)
            let queryLiterals = query.split(' ');
            let queries = []
            let qlProcessed = [];
            queryLiterals.forEach ( ql => { 
                qlProcessed.push(ql.toLowerCase())
                qlProcessed.push(ql.toUpperCase())
                qlProcessed.push(ql.substr(0,1).toUpperCase() + ql.substr(1).toLowerCase());
             } )

             qlProcessed.forEach( ql => {
                queries.push(collection.orderBy('firstName').startAt(ql).endAt(ql+"\uf8ff").get())
                queries.push(collection.orderBy('lastName').startAt(ql).endAt(ql+"\uf8ff").get())
                queries.push(collection.orderBy('pesel').startAt(ql).endAt(ql+"\uf8ff").get())
                queries.push(collection.orderBy('phone').startAt(ql).endAt(ql+"\uf8ff").get())
                queries.push(collection.orderBy('name').startAt(ql).endAt(ql+"\uf8ff").get())
            } )

            

            let results = []



            Promise.all(queries).then( (snaps) => {
                snaps.forEach( (snap) => { results = results.concat(snap.docs).unique() } )
                let data = results.map( (res)=>res.data() )
                Patient.searchByDocsTokens(query).then(ps => {
                    data = data.concat(ps);
                    let uniqueData = [];
                    data.forEach( el => { if (uniqueData.map( uEl => uEl.id ).includes(el.id) === false ) {  uniqueData.push(el)  } });
                    uniqueData.forEach( ud => {
                        ud.Relevance = 0
                        let ql = queryLiterals
                        for (const [, value] of Object.entries(ud)) {
                            if (ql.includes(value)) {
                                ud.Relevance += 1;
                                ql = ql.filter( a => a !== value )
                            }
                        }
                    } )
                    uniqueData.sort( (a,b) => b.Relevance - a.Relevance );
                    resolve(uniqueData)
                })
            } ).catch ( (err) => {
                reject(err)
            })
            

            
        } )
        
    }
}

Array.prototype.unique = function() {
    var a = this.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
};

export const patientConverter = {
    /**
     * 
     * @param {Patient} patient 
     * @returns any
     */
    toFirestore: (patient) => {
        return {
            firstName: patient.firstName,
            lastName: patient.lastName,
            mail: patient.mail,
            phone: patient.phone,
            pesel: patient.pesel
        }
    },

    /**
     * 
     * @param {firebase.firestore.QueryDocumentSnapshot} snapshot 
     * @param {firebase.firestore.SnapshotOptions} options 
     * @returns {Patient}
     */
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options)
        return new Patient(data.firstName, data.lastName, data.mail, data.phone, data.pesel, snapshot.id)
    }
}