<template>
<div v-if="this.$parent.ProcessingIndicator===true" class="processing-placeholder">
    <div class="lds-dual-ring"></div>
</div>


</template>


<style>
.processing-placeholder {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: rgba(255,255,255,0.5);
    z-index: 100000;
    backdrop-filter: blur(5px);
}


.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: fixed;
  left: calc(50% - 40px);
  top: calc(50vh - 40px);
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #4caf50;
  border-color: #4caf50 transparent #4caf50 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


</style>

<script>

export default {
    name: "ProcessingPlaceholder",
    components: {  },

    setup() {
        
    },

    created() {

    }, 

    data() {
        return { }
    }
}
</script>

