<template>
    <div class="patient-edit">
        <div class="row">
            <div class="col-12">
                <div v-if=" GlobalWarning != '' " class="alert alert-danger">
                    <span>{{ GlobalWarning }}</span>
                </div>  
            </div>
            <div class="col-12">
                <div v-if="!IsValidPesel" class="alert alert-danger">
                    <span>Wprowadzono nieprawidłowy numer PESEL.</span>
                </div>  
            </div>
            <div class="col-12">
                <div v-if="!IsNewPeselProp" class="alert alert-danger">
                    <span style="float: left; margin-top:18px;">W bazie istnieje juz pacjent zarejestrowany z podanym numerem PESEL:&nbsp;</span>
                    <span style="font-weight: bold; float:left; margin-top:18px; margin-left: 10px;">{{ExistingPatient.firstName}} {{ExistingPatient.lastName}}</span>
                    <router-link class="btn btn-outline pull-right" :to="{name: 'PatientShow', params: {id: ExistingPatient.id}}">
                        Przejdź do pacjenta
                        <span class="material-icons">arrow_forward_ios</span>
                    </router-link>
                    <div style="clear:both;" class="clearfix"></div>
                </div>  
            </div>
        </div>
       
        <Card v-bind:HeaderText="this.GetHeaderText()" SubheaderText="Wprowadzanie danych pacjenta" LinkText="<span class='material-icons'>close</span> Anuluj" :ActionTo="Close">
            <form v-on:submit.prevent="onSubmit">
                <div class="row mt-4">
                  <div class="col-md-4">
                    <div class="form-group bmd-form-group is-filled">
                      <label class="bmd-label-floating">PESEL</label>
                      <input required v-model="this.Patient.pesel" v-mask="'###########'" type="text" class="form-control">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group bmd-form-group is-filled">
                      <label class="bmd-label-floating">Nazwisko</label>
                      <input required v-model="this.Patient.lastName" type="text" class="form-control">
                    </div>
                  </div>
                  <div class="col-md-4">
                        <div class="form-group bmd-form-group is-filled">
                          <label class="bmd-label-floating">Imię</label>
                          <input required v-model="this.Patient.firstName" type="text" class="form-control">
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-4">
                        <div class="form-group bmd-form-group is-filled">
                          <label class="bmd-label-floating">E-mail</label>
                          <input v-model="this.Patient.mail" type="mail" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group bmd-form-group is-filled">
                          <label class="bmd-label-floating">Telefon</label>
                          <input v-model="this.Patient.phone" type="tel" v-mask="['+48 ### ### ###']" class="form-control">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12"><hr/></div>
                    <div class="col-12">
                        <button v-if="!IsEditing" v-on:click="SaveData" class="btn btn-success pull-right">
                            <span class="material-icons mr-2">done</span>
                            Dodaj pacjenta
                        </button>
                        <button v-if="IsEditing" v-on:click="UpdateData" class="btn btn-success pull-right">
                            <span class="material-icons mr-2">done</span>
                            Zaktualizuj
                        </button>

                        <button v-if="IsEditing && HavePermissionsToRemove" v-on:click="RemovePatient" class="btn btn-danger pull-right mr-3">
                            <span class="material-icons mr-2">delete</span>
                            Usuń pacjenta
                        </button>

                    </div>
                </div>
            </form>
        </Card>
    </div>
</template>

<script>
import Card from '@/components/Card.vue'
import Patient from '../model/Patient'
import UserModel from '../model/UserModel'



export default {
    name: 'PatientNew',
    components: { Card },

    methods: {
       GetHeaderText() {
           if ( (this.Patient.firstName == '') || (this.Patient.lastName == '') ) return "Nowy pacjent"
           else return this.Patient.firstName + " " + this.Patient.lastName;
       },

       Close() {
            if (this.IsEditing) this.$router.push('/patients/' + this.Patient.id + "/")
            else this.$parent.ShowAddPatient = false;
        },

        

       async SaveData() {
           let validated = false;
           if ( (this.Patient.firstName.length > 3) && (this.Patient.lastName.length > 3) && (this.Patient.pesel.length == 11) && (this.IsValidPesel == true) && (this.IsNewPeselProp) ) validated = true;
           if (validated == true) {
                this.GlobalWarning = "";
                this.Patient.create().then( (p)=>{
                    this.$router.push('/patients/' + p.id + '/');
                } );
           } else {
               this.GlobalWarning = "Nie mozna zapisać danych. Sprawdź poprawność wprowadzonych informacji."
           }
           
       },

       async UpdateData() {
           let validated = false;
           if ( (this.Patient.firstName.length > 3) && (this.Patient.lastName.length > 3) && (this.Patient.pesel.length == 11) && (this.IsValidPesel == true) && (this.IsNewPeselProp) ) validated = true;
           if (validated == true) {
                this.GlobalWarning = "";
                this.Patient.update().then( ()=>{ 
                    this.$router.push("/patients/" + this.Patient.id + "/");
                 } ).catch( (err) => {
                     console.log(err);
                     this.GlobalWarning = "Nie mozna zapisać danych. Sprawdź poprawność wprowadzonych informacji."
                 } )
           } else {
               this.GlobalWarning = "Nie mozna zapisać danych. Sprawdź poprawność wprowadzonych informacji."
           }
       },

       RemovePatient() {
            var r = confirm("Czy na pewno chcesz usunąć pacjenta i wszystkie dokumenty do niego przypisane?");
            if (r == true) {
                this.Patient.remove().then( ()=>{ 
                    this.$router.push('/patients/')
                 } )
            }
        },
    },

    computed: {
        IsValidPesel: function() {
            let pesel = this.Patient.pesel;
            if (pesel.length !== 11) return true;
            let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
            let sum = 0;
            let controlNumber = parseInt(pesel.substring(10, 11));

            for (let i = 0; i < weight.length; i++) {
                sum += (parseInt(pesel.substring(i, i + 1)) * weight[i]);
            }
            sum = sum % 10;
            let isValid = (10 - sum) % 10 === controlNumber;
            return isValid
        },

        PESEL: function() { return this.Patient.pesel },

        HavePermissionsToRemove: function () { return UserModel.LoggedUser.adminLvl >= 3 } 
        
    },

    watch: {
        PESEL: function(val) {
            if ( (val.length == 11) && (this.IsValidPesel) && (!this.IsEditing) ) {
                Patient.fetchQueryData(this.PESEL).then( (p) => {
                    if (p.length > 0) { this.IsNewPeselProp = false; this.ExistingPatient = p.pop() }
                    else { this.IsNewPeselProp = true }
                } )
            } else {
                this.IsNewPeselProp = true;
            }
        }
    },

    created() {
        let pId = this.$route.params.id;
        if (pId) {
            this.IsEditing = true;
            Patient.GetById(pId).then( p => {
                this.Patient = p;
                document.querySelector(".patient-edit input").focus();
            })
        } 
    },

    data() {
        
        return { 
            Patient: new Patient("","","","",""), 
            ExistingPatient: new Patient("","","","",""),
            PatientId: 0,
            GlobalWarning: "",
            IsNewPeselProp: true,
            IsEditing: false,
        }
    }
}

</script>