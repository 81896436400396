<template>
<div>
  <ProcessingPlaceholder></ProcessingPlaceholder>
  <SideBar/>
  <div class="main-panel">
    <NavBar/>
    <div class="content">
      <div class="container-fluid">
        <router-view/>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import SideBar from './components/SideBar.vue'
import NavBar from './components/NavBar.vue'
import ProcessingPlaceholder from './components/LoginPlaceholder.vue'
//import UserModel from './model/UserModel'

export default {
  name: 'App',
  components: {
    SideBar,
    NavBar,
    ProcessingPlaceholder
  },

  watch: {
    QueryField() {
      if (this.$route.path != '/patients') {
        this.$router.push('/patients');
      }
    }
  },

  data() {
    return { AuthUser: null, QueryField: "", ProcessingIndicator: false } 
  },

  created() {
    //let authToken = this.$cookie;
    //if (authToken) {
    //  let usr = UserModel.GetAuthenticatedUser();
   // }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
