<style scoped>
  .check-icon { vertical-align: middle; color: darkgreen; }
  .wait-icon { vertical-align: middle; color: rgb(169, 169, 169); }
</style>

<template>
  <div class="dashboard">
    <div class="row">

      <div class="col-md-4">
        <div class="card card-stats">
          <div class="card-header card-header-success card-header-icon">
            <div class="card-icon">
              <i class="material-icons">assessment</i>
            </div>
            <p class="card-category">Ochrona danych</p>
            <h4 class="card-title">
              replikacja bazy
              <span v-if="IndicatorA" class="material-icons ml-2 check-icon">check_circle</span>
              <span v-if="!IndicatorA" class="material-icons ml-2 wait-icon">refresh</span>
              </h4>
            <h4 class="card-title">
              kopia zapasowa
              <span v-if="IndicatorB" class="material-icons ml-2 check-icon">check_circle</span>
              <span v-if="!IndicatorB" class="material-icons ml-2 wait-icon">refresh</span>
            </h4>
            <h4 class="card-title mb-4">
              szyfrowanie danych
              <span v-if="IndicatorC" class="material-icons ml-2 check-icon">check_circle</span>
              <span v-if="!IndicatorC" class="material-icons ml-2 wait-icon">refresh</span>
            </h4>
          </div>
          
        </div>
      </div>

      <div class="col-md-4">
        <div class="card card-stats">
          <div class="card-header card-header-info card-header-icon">
            <div class="card-icon">
              <i class="material-icons">people</i>
            </div>
            <p class="card-category">Pacjenci</p>
            <h3 class="card-title">{{PatientsNumber}}
              <small>osób</small>
            </h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons text-info mr-1">info</i>
              Liczba zarejestrowanych pacjentów
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card card-stats">
          <div class="card-header card-header-primary card-header-icon">
            <div class="card-icon">
              <i class="material-icons">description</i>
            </div>
            <p class="card-category">Dokumenty</p>
            <h3 class="card-title">{{DocumentsNumber}}
              <small>plików</small>
            </h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons text-primary mr-1">info</i>
              Liczba zarejestrowanych dokumentów
            </div>
          </div>
        </div>
      </div>
      
      

    </div>
    
  </div>
</template>

<script>

import { fbFunctions,  } from '../firebaseDb';


export default {
  name: 'Home',
  components: {
    
    
  },

  created() {
    let patientCounter = fbFunctions.httpsCallable('GetAllPatientsNumber');
    patientCounter.call().then( (res) => { this.PatientsNumber = res.data.snapSize } );

    let docsCounter = fbFunctions.httpsCallable('GetAllDocumentsNumber');
    docsCounter.call().then( (res) => { this.DocumentsNumber = res.data.snapSize } );

    setTimeout( () => {
      this.IndicatorA = true;
    },  Math.round(Math.random()*3000))

    setTimeout( () => {
      this.IndicatorB = true;
    },  Math.round(Math.random()*3000))

    setTimeout( () => {
      this.IndicatorC = true;
    },  Math.round(Math.random()*3000))

  },

  data() {
    return { 
      PatientsNumber: 0,
      DocumentsNumber: 0,
      IndicatorA: false,
      IndicatorB: false,
      IndicatorC: false
     }
  }
}




</script>
