import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import 'firebase/compat/performance';
import 'firebase/compat/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyDTavT31OhUM5mVr_FeJAxyPnUkOXuRt6M",
    authDomain: "wpsl-medstorage.firebaseapp.com",
    projectId: "wpsl-medstorage",
    storageBucket: "wpsl-medstorage.appspot.com",
    messagingSenderId: "376525644001",
    appId: "1:376525644001:web:7cabed1ce894e09a7b57a0",
    measurementId: "G-408J78XZL9"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const db = firebaseApp.firestore();
export const fbApp = firebaseApp;
export const fbFunctions = firebase.functions(fbApp);
export const fbStorage = fbApp.storage('gs://wpsl-medstorage');
export const fbPerf = fbApp.performance();
export const fbAnal = fbApp.analytics();