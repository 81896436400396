<template>
    <div class="sidebar" data-color="green" data-background-color="white" data-image="/img/sidebar-1.jpg">
      <div class="logo"><a href="/" class="simple-text logo-normal">
          <img src="/img/logo.png"/>
        </a></div>
      <div class="sidebar-wrapper">
        <ul class="nav">
          <li class="nav-item ">
            <router-link to="/home" class="nav-link">
              <i class="material-icons">dashboard</i>
              <p>home</p>
            </router-link>
          </li>
          <li class="nav-item ">
            <router-link to="/patients" class="nav-link">
              <i class="material-icons">person</i>
              <p>pacjenci</p>
            </router-link>
          </li>
          <li class="nav-item ">
            <router-link to="/types" class="nav-link">
              <i class="material-icons">list</i>
              <p>słowniki</p>
            </router-link>
          </li>
          <li class="nav-item ">
            <router-link to="/users" class="nav-link">
              <i class="material-icons">people</i>
              <p>uzytkownicy</p>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
</template>


<script>

export default {
    name: "SideBar"
}
</script>
