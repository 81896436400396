<template>
    <div class="users-edit">
        <div class="row">
            <div class="col-12">
                <div v-if=" GlobalWarning != '' " class="alert alert-danger">
                    <span>{{ GlobalWarning }}</span>
                </div>  
            </div>
        </div>
        <Card v-bind:HeaderText="this.GetHeaderText()" SubheaderText="Wprowadzanie danych uzytkownika">
            <form v-on:submit.prevent="onSubmit">
                <div class="row mt-4">
                    <div class="col-md-4">
                        <div class="form-group bmd-form-group is-filled">
                          <label class="bmd-label-floating">Imię</label>
                          <input v-model="this.User.firstName" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group bmd-form-group is-filled">
                          <label class="bmd-label-floating">Nazwisko</label>
                          <input v-model="this.User.lastName" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group bmd-form-group is-filled">
                          <label class="bmd-label-floating">E-mail</label>
                          <input v-model="this.User.email" type="mail" class="form-control">
                        </div>
                    </div>
                    
                </div>
                <div class="row mt-4">
                    <div class="col-md-12">
                        <div v-if=" WarningText != '' " class="alert alert-warning">
                            <span>{{ WarningText }}</span>
                        </div>  
                    </div>
                    <div class="col-md-6">
                        <div class="form-group bmd-form-group is-filled">
                          <label class="bmd-label-floating">Hasło</label>
                          <input type="password" class="form-control" v-model="Password">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group bmd-form-group is-filled">
                          <label class="bmd-label-floating">Powtórz hasło</label>
                          <input type="password" class="form-control" v-model="PasswordRepeat">
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-12">
                        <div class="form-group bmd-form-group is-filled ml-1">
                            <label class="bmd-label-floating">Poziom dostępu</label>
                            <div class="container">
                                <div class="row">
                                    <div class="form-check form-check-radio col-md-3">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="radio" name="adminLvl" id="adminLvl-0" value="0" v-model="this.User.adminLvl" >
                                            zablokowany
                                            <span class="circle">
                                                <span class="check"></span>
                                            </span>
                                        </label>
                                    </div>

                                    <div class="form-check form-check-radio col-md-3">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="radio" name="adminLvl" id="adminLvl-1" value="1" v-model="this.User.adminLvl" >
                                            uzytkownik
                                            <span class="circle">
                                                <span class="check"></span>
                                            </span>
                                        </label>
                                    </div>

                                    <div class="form-check form-check-radio col-md-3">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="radio" name="adminLvl" id="adminLvl-2" value="2" v-model="this.User.adminLvl" >
                                            kierownik
                                            <span class="circle">
                                                <span class="check"></span>
                                            </span>
                                        </label>
                                    </div>
                                    <div class="form-check form-check-radio col-md-3">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="radio" name="adminLvl" id="adminLvl-3" value="3" v-model="this.User.adminLvl" >
                                            administrator
                                            <span class="circle">
                                                <span class="check"></span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12"><hr/></div>
                    <div class="col-12">
                        <button v-on:click="SaveData" class="btn btn-success pull-right">
                            <span class="material-icons mr-2">done</span>
                            utwórz konto
                        </button>
                    </div>
                </div>
            </form>
        </Card>
    </div>
</template>

<script>
import Card from '@/components/Card.vue'
import UserModel from '../model/UserModel'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
var md5 = require('md5');



export default {
    name: 'UsersNew',
    components: { Card },

    methods: {
       GetHeaderText() {
           if ( (this.User.firstName == '') || (this.User.lastName == '') ) return "Nowy uzytkownik"
           else return this.User.firstName + " " + this.User.lastName;
       },

       async SaveData() {
           let validated = false;
           if ( (this.User.firstName != '') && (this.User.lastName != '') && (this.User.email != '') && (this.WarningText == '') ) validated = true;
           if (validated == true) {
                if (this.Password != '')
                this.User.password = md5(this.Password);
                this.User.adminLvl = parseInt(this.User.adminLvl);
                this.GlobalWarning = "";

                const auth = getAuth();
                let userCredential = await createUserWithEmailAndPassword(auth, this.User.email, this.User.password);
                const fbUser = userCredential.user;
                await UserModel.Create(fbUser.uid, this.User);
                this.$router.push( { path: '/users' } );
           } else {
               this.GlobalWarning = "Nie mozna zapisać danych. Sprawdź poprawność wprowadzonych informacji."
           }
           
       }
    },

    computed: {
        WarningText: function() {
            if (this.Password == '') return ""
            else if (this.Password.length < 8) return "Podane hasło jest za krótkie."
            else if (this.Password != this.PasswordRepeat) return "Podane hasła róznią się."
            else return ""
        }
    },

    created() {

    },

    data() {
        return { 
            User: { lastName:"", firstName: "" }, 
            UserId: 0,
            Password: "",
            PasswordRepeat: "",
            GlobalWarning: ""
        }
    }
}

</script>