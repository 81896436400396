<template>
    <div class="patient-show">
        <Card v-bind:HeaderText="Patient.firstName+' '+Patient.lastName" SubheaderText="Karta pacjenta" LinkText="<span class='material-icons'>edit</span> edytuj pacjenta" :ActionTo="EditPatient">
            <div class="jumbotron pt-3 pb-3">
                <div class="row">
                    <div v-if="Patient.phone" class="col pt-2"><span class="material-icons float-left mr-3">phone</span>{{Patient.phone}}</div>
                    <div v-if="Patient.mail" class="col pt-2"><span class="material-icons float-left mr-3">mail</span>{{Patient.mail}}</div>
                    <div class="col pt-2"><span class="material-icons float-left mr-3">fingerprint</span>PESEL: {{Patient.pesel}}</div>
                    <div class="col">
                        <button class="btn btn-success pull-right btn-sm" v-if="!AddDocs" v-on:click="ShowAddDocs"><span class="material-icons mr-2">add</span>dodaj nowy dokument</button>
                    </div>
                </div>
            </div>

            <PatientDocuments v-if="PatientLoaded" :patient="Patient"></PatientDocuments>
            <AddPatientDocuments v-if="AddDocs" :patient="Patient"></AddPatientDocuments>

        </Card>
    </div>
</template>

<script>
import Card from '@/components/Card.vue'
import PatientDocuments from '@/components/PatientDocuments.vue'
import AddPatientDocuments from '@/components/AddPatientDocuments.vue'
import Patient from '../model/Patient'



export default {
    name: 'PatientShow',
    components: { Card, PatientDocuments, AddPatientDocuments },
    

    methods: {
        RemovePatient() {
            var r = confirm("Czy na pewno chcesz usunąć pacjenta i wszystkie dokumenty do niego przypisane?");
            if (r == true) {
                this.Patient.remove().then( ()=>{ 
                    this.$router.push('Patients')
                 } )
            }
        },

        EditPatient() {
            this.$router.push("/patients/edit/" + this.Patient.id + "/");
        },

        ShowAddDocs() {
            this.PatientLoaded = false;
            this.AddDocs = true;
        },

        HideAddDocs() {
            this.PatientLoaded = true;
            this.AddDocs = false;
        },

    },

    computed: {
        
    },

    watch: {
       
    },

    async beforeCreate() {
        
    },

    async created() {
        
        this.PatientId = this.$route.params.id;
        this.Patient = await Patient.GetById(this.PatientId)
        this.PatientLoaded = true;

        
    },

    data() {
        
        return { 
            Patient: new Patient("","","","",""), 
            PatientId: 0,
            Docs: [],
            PatientLoaded: false,
            AddDocs: false
        }
    }
}

</script>