<template>
<div class="types row">

    <div class="col">
        <Card HeaderText="Poradnie" SubheaderText="Lista poradni do przypisywania w bazie">
            <div class="table-responsive">
                <table class="table">
                    <thead class="text-success">
                        <tr>
                            <th>#</th>
                            <th>Nazwa</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(doctor,i) in doctors" :key="doctor.id">
                            <td>{{i+1}}</td>
                            <td>{{doctor.name}}</td>
                            <td><button v-if="canDelete" v-on:click="removeDoctor(doctor.id)" class="float-right btn btn-sm btn-outline-danger"><span class="material-icons">delete</span></button></td>
                        </tr>
                        <tr v-if="addDoctor">
                            <td colspan="2">
                                <div class="form-group bmd-form-group">
                                    <label class="bmd-label-floating">nazwa poradni</label>
                                    <input type="text" v-model="newDoctor" class="form-control">
                                </div>
                            </td>
                            <td>
                                <button v-on:click="insertDoctor()" class="btn btn-outline-success btn-sm float-right ml-2"><span class="material-icons">check</span></button>
                                <button v-on:click="addDoctor=false" class="btn btn-outline-danger btn-sm float-right"><span class="material-icons">clear</span></button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="3">
                                <button class="float-right btn btn-sm btn-outline-success" v-if="!addDoctor" v-on:click="addDoctor=true">
                                    <span class="material-icons">add</span>dodaj
                                </button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </Card>
    </div>

    <div class="col">
        <Card HeaderText="Rodzaje dokumentów" SubheaderText="Lista typów do oznaczania skanów">
            <div class="table-responsive">
                <table class="table">
                    <thead class="text-success">
                        <tr>
                            <th>#</th>
                            <th>Nazwa</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(doctype,i) in doctypes" :key="doctype.id">
                            <td>{{i+1}}</td>
                            <td>{{doctype.name}}</td>
                            <td><button v-if="canDelete" v-on:click="removeDoctype(doctype.id)" class="float-right btn btn-sm btn-outline-danger"><span class="material-icons">delete</span></button></td>
                        </tr>
                        <tr v-if="addDoctype">
                            <td colspan="2">
                                <div class="form-group bmd-form-group">
                                    <label class="bmd-label-floating">określenie typu dokumentu</label>
                                    <input type="text" v-model="newDoctype" class="form-control">
                                </div>
                            </td>    
                            <td>
                                <button v-on:click="insertDoctype()" class="btn btn-outline-success btn-sm float-right ml-2"><span class="material-icons">check</span></button>
                                <button v-on:click="addDoctype=false" class="btn btn-outline-danger btn-sm float-right"><span class="material-icons">clear</span></button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="3">
                                <button class="float-right btn btn-sm btn-outline-success" v-if="!addDoctype" v-on:click="addDoctype=true">
                                    <span class="material-icons">add</span>dodaj
                                </button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </Card>
    </div>

</div>
</template>


<script>

import Card from '@/components/Card'
import { db } from '../firebaseDb'
import UserModel from '../model/UserModel';



export default ({
    name: 'Types',
    components: { Card },
    data() {
        return { 
            doctors: [], 
            doctypes: [], 
            newDoctor: '', 
            newDoctype: '', 
            addDoctor: false, 
            addDoctype: false,
            canDelete: false
        }
    },
    created() {
        db.collection('doctor').orderBy('name').onSnapshot( (doc) => { 
            this.doctors = doc.docs.map( (el) => { 
                let obj = el.data()
                obj.id = el.id
                return obj
            } )
        } );
        db.collection('doctype').orderBy('name').onSnapshot( (doc) => { 
            this.doctypes = doc.docs.map( (el) => { 
                let obj = el.data()
                obj.id = el.id
                return obj
            } )
        } );

        this.canDelete = UserModel.LoggedUser.adminLvl >= 3;
    },

    methods: {
        insertDoctor() {
            db.collection('doctor').add( {name: this.newDoctor} ).then( () => {
                this.newDoctor = '';
                this.addDoctor = false;
            } ).catch( (err) => {
                console.log(err);
            });
        },

        removeDoctor(docId) {
            var r = confirm("Czy na pewno chcesz usunąć element?");
            if (r == true) {
                db.collection('doctor').doc(docId).delete();
            }
        },

        insertDoctype() {
            db.collection('doctype').add( {name: this.newDoctype} ).then( () => {
                this.newDoctype = '';
                this.addDoctype = false;
            } ).catch( (err) => {
                console.log(err);
            });
        },

        removeDoctype(docId) {
            var r = confirm("Czy na pewno chcesz usunąć element?");
            if (r == true) {
                db.collection('doctype').doc(docId).delete();
            }
        }
    }

    
})
</script>
