import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueCookie from 'vue-cookie'
import VueTheMask from 'vue-the-mask'

let vueApp = createApp(App)
vueApp.user = {}
vueApp.use(VueCookie)
vueApp.use(VueTheMask);
vueApp.use(router)
vueApp.mount('#app')

//module.exports = { app,db, collection, getDocs };
 