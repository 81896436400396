<template>
    <div class="users-edit">
        <div class="row">
            <div class="col-12">
                <div v-if=" GlobalWarning != '' " class="alert alert-danger">
                    <span>{{ GlobalWarning }}</span>
                </div>  
            </div>
        </div>
        <Card HeaderText='Logowanie do systemu' SubheaderText="Wprowadź dane do logowania">
            <form v-on:submit.prevent="onSubmit">
                <div class="row mt-4">
                    <div class="col-md-4">
                        <div class="form-group bmd-form-group is-filled">
                          <label class="bmd-label-floating">Adres e-mail</label>
                          <input name="email" v-model="this.UserCandidate.email" type="email" class="form-control">
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-4">
                        <div class="form-group bmd-form-group is-filled">
                          <label class="bmd-label-floating">Hasło</label>
                          <input name="password" v-model="this.UserCandidate.password" type="password" class="form-control">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12"><hr/></div>
                    <div class="col-12">
                        <button v-on:click="login" class="btn btn-success pull-right">
                            <span class="material-icons mr-2">login</span>
                            zaloguj
                        </button>
                    </div>
                </div>
            </form>
        </Card>
    </div>
</template>

<script>
import Card from '@/components/Card.vue'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import UserModel from '../model/UserModel';



export default {
    name: 'Login',
    components: { Card },

    methods: {
        login() {
           const auth = getAuth();
           signInWithEmailAndPassword(auth, this.UserCandidate.email, this.UserCandidate.password).then(() => {
               UserModel.GetLoggedUser( usr => {
                   UserModel.LoggedUser = usr
                   this.$router.push( { path: '/home' } ); 
               } );
               //const user = userCredential.user;
               
            })
            .catch((error) => {
                const errorMessage = error.message;
                this.GlobalWarning = "Błąd: " + errorMessage;
            });
        }
    },

    computed: {
       
    },

    created() {

    },

    data() {
        return { 
            UserCandidate: { email: "", password: "" }, 
            GlobalWarning: ""
        }
    }
}

</script>