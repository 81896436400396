<template>
    <div class="users">
        <Card HeaderText="Lista uzytkowników systemu" SubheaderText="Zarządzaj uprawnieniami i kontami uzytkowników" LinkText="<span class='material-icons'>add</span> Dodaj" LinkTo="/users/new">
            <div class="table-responsive">
                <table class="table">
                    <thead class="text-success">
                        <tr>
                            <th>#</th>
                            <th>Imię i nazwisko</th>
                            <th>Poziom dostępu</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(user,i) in Users" :key="user.id">
                            <td>{{i+1}}</td>
                            <td>{{user.firstName}} {{user.lastName}}</td>
                            <td>{{this.GetLvlDesc(user.adminLvl)}}</td>
                            <td v-if="IsEditVisible(user.uuid)">
                                <router-link class="btn btn-success btn-sm pull-right" :to="{name: 'UserEdit', params: {id: user.id}}" >
                                    <span class="material-icons">edit</span>
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Card>
    </div>
</template>

<script>
import Card from '@/components/Card.vue'
import UserModel from '../model/UserModel'

export default {
    name: 'Users',
    components: { Card },


    methods: {
        GetLvlDesc(lvl) {
            return UserModel.GetAdminLvlDesc(lvl);
        },

        IsEditVisible(uuid) {
            if (UserModel.LoggedUser.uuid == uuid) return true
            else return false
        }
    },

    async created() {
        this.Users = await UserModel.FetchAll();        
    },

    data() {
        return { Users: [] }
    }
    
}



</script>