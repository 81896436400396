<template>
    <form v-on:submit.prevent>
        <div class="row mt-4">

            <div class="col-md-4">
                <div class="form-group bmd-form-group is-filled">
                    <label class="bmd-label-floating">data wystawienia dokumentu</label>
                    <input id="dateIssued" required v-model="newMD.dateIssued" type="date" class="form-control">
                </div>
            </div>

            <div class="col-md-4">
                <div class="form-group bmd-form-group is-filled">
                    <label class="bmd-label-floating">wybierz poradnię</label>
                    <select v-model="newMD.doctor" class="form-control selectpicker" data-style="btn btn-link" id="doctor">
                        <option value=""> --- wybierz poradnie ---</option>
                        <option v-for="d in Doctors" v-bind:key="d.id" v-bind:value="d.id">
                            {{ d.name }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="col-md-4">
                <div class="form-group bmd-form-group is-filled">
                    <label class="bmd-label-floating">wybierz rodzaj dokumentu</label>
                    <select v-model="newMD.doctype" class="form-control selectpicker" data-style="btn btn-link" id="doctor">
                        <option value=""> --- wybierz rodzaj dokumentu ---</option>
                        <option v-for="d in Doctypes" v-bind:key="d.id" v-bind:value="d.id">
                            {{ d.name }}
                        </option>
                    </select>
                </div>
            </div>

        </div>

        <div class="row mt-4">
            <div class="col-md-12">
                <div v-if="!IsUploading" v-on:click="ShowAddFileWindow" v-bind:class="{ active: dragActive }" id="filesField" v-cloak @drop.prevent="addFile" @dragover.prevent @dragenter.prevent="dragActive=true" @dragleave="dragActive=false">
                    <input type="file" style="display: none" name="fileInput" id="fileInput"/>
                    
                    <div class="singleFile" v-for="file in Files" v-bind:key="file.name">
                        <span>{{ file.name }}</span>
                        <button v-if="file.ref !== undefined" @click.stop="removeFile(file)" class="btn btn-danger btn-sm pull-right">X</button>
                    </div>
                    
                    <p>Przeciągnij tutaj pliki do załadowania. Mozesz równiez kliknąć i wybrać plik w okienku.</p>
                </div>

                <div id="filesField" v-if="IsUploading">
                    <p>Trwa wgrywanie plików...</p>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col">
                <button type="reset" class="btn btn-primary" v-on:click="goBack"><span class="material-icons mr-2">arrow_back</span>Powrót do listy</button>
            </div>
            <div class="col">
                <button type="button" class="btn btn-success pull-right" v-on:click="saveMD"><span class="material-icons mr-2">add</span>Zapisz dane</button>
            </div>
            
        </div>
    </form>
</template>

<script>

import { db, fbStorage } from '../firebaseDb'
import { MedDoc } from '../model/MedDoc'
import Patient from '../model/Patient'
import UserModel from '../model/UserModel'

export default {
    name: 'AddPatientDocuments', 
    props: { patient: Patient },

    methods: {

        ShowAddFileWindow() {
            let inputEl = document.querySelector("#fileInput")
            inputEl.click()
            inputEl.onchange = (el) => {
                let e = { dataTransfer: { files: el.target.files } }
                this.addFile(e);
            }
        },

        addFile(e) {
            let droppedFiles = e.dataTransfer.files;
            if(!droppedFiles) return;
            ([...droppedFiles]).forEach(f => { 
                let fileExt = f.name.split('.').pop();
                let fileName = this.patient.id + '_' + Date.now() + '.' + fileExt;
                let ref = fbStorage.ref(fileName);
                this.IsUploading = true;

                let tasks = [ref.put(f)];

                
                let fReader = new FileReader();
                fReader.addEventListener('load', (event) => {
                    if (fileExt == 'pdf') { 
                        let data = event.target.result;
                        tasks.push(this.extractTextFromPDF( {data: atob(data.substr(28))} ));
                    }

                    Promise.all(tasks).then( (results) => {
                        let snap = results[0];
                        let tokens = results.length == 2 ? results[1] : []
                        f.ref = snap.ref;
                        this.Files.push(f) 
                        this.newMD.cloudFiles.push(fileName);
                        this.TextData = this.TextData.concat(tokens);
                        this.TextData = this.TextData.filter(this.onlyUnique);
                        this.IsUploading = false;
                        this.dragActive = false;
                    } ).catch( err => { console.log(err) } )
                });
                fReader.readAsDataURL(f);
            });
        },

        extratctTextFromPage(pdf, pageNumber) {
            return new Promise( (resolve) => {
                let output = [];
                pdf.getPage(pageNumber).then( (page) => {
                    page.getTextContent().then( (textContent) => {
                        textContent.items.forEach ( (item) => { output.push(item.str) } )
                        resolve(output);
                    } ).catch( () => resolve([]) );
                } ).catch( () => resolve([]) );
            })
        },

        extractTextFromPDF(pdfData) {
            return new Promise( ( resolve, reject) => {
                let pdfjsLib = window['pdfjs-dist/build/pdf'];
                pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
                pdfjsLib.getDocument(pdfData).promise.then( (pdf) => {
                    let output = [];
                    let taskArray = [];
                    let page = 0;
                    while( page < pdf.numPages ){
                        page++;
                        taskArray.push(this.extratctTextFromPage(pdf, page))
                    }
                    Promise.all
                    Promise.all(taskArray).then (results => {
                        results.forEach( result => { output = output.concat(result) } )
                        let uniqueTokens = output.filter(this.onlyUnique);
                        resolve(uniqueTokens)
                    }).catch ( err => { reject(err) })
                } )
            } )
        },

        onlyUnique(value, index, self) {
            return self.indexOf(value) === index && value.length >= 4;
        },

        saveMD() {
            let md = this.newMD;

            let mdToSave = new MedDoc(
                new Date(md.dateIssued), 
                db.collection('doctor').doc(md.doctor),
                db.collection('doctype').doc(md.doctype),
                db.collection('patient').doc(this.patient.id),
                md.cloudFiles,
                db.collection('users').doc(UserModel.LoggedUser.id),
                this.TextData,
                new Date(),
                null)
            
            mdToSave.create().then ( () => {
                this.newMD.cloudFiles = [];
                this.newMD.doctype = '';
                this.Files = [];

                window.jNotify(
                    { icon: "add_alert", message: "Dokumenty zostały zapisane." },
                    { type: 'success', timer: 2000 }
                );
                
            } );
        },

        goBack() {
            this.$parent.$parent.HideAddDocs();
        },

        removeFile(file){
            file.ref.delete().then( () => {
                this.Files = this.Files.filter(f => { return f != file });      
            } )
        }
    },

    computed: {
        uploadDisabled() {
            return this.Files.length === 0;
        }
    },

    watch: {
       
    },


    async created() {
        this.Doctypes = (await db.collection('doctype').get()).docs.map( d => { return { name: d.data().name, id: d.id } } )
        this.Doctors = (await db.collection('doctor').get()).docs.map( d => { return { name: d.data().name, id: d.id } } )
        document.querySelector("#dateIssued").focus();

        this.newMD.patient = db.collection('patient').doc(this.patient.id);
        this.newMD.enteredBy = db.collection('users').doc(UserModel.LoggedUser.id);

        
    },

    data() {
        return { 
            Patient: this.patient,
            Docs: [],
            Doctors: [],
            Doctypes: [],
            newMD: new MedDoc('', '', '', ''),
            Files: [],
            TextData: [],
            dragActive: false,
            IsUploading: false,
        }
    }
}

</script>