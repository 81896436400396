<template>
    <!-- Navbar -->
      <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
        <div class="container-fluid">
          <div class="navbar-wrapper">
            <a class="navbar-brand" href="javascript:;"><span class="nav-brand">Med-Storage</span> System Informacji Medycznej dla WPSL Poznań</a>
          </div>
          <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-end">
            <form v-if="IsLogged" class="navbar-form">
              <div class="input-group no-border">
                <input type="text" v-model="this.$parent.QueryField" class="form-control" placeholder="Search...">
                <button type="submit" class="btn btn-white btn-round btn-just-icon">
                  <i class="material-icons">search</i>
                  <div class="ripple-container"></div>
                </button>
              </div>
            </form>
            <ul class="navbar-nav">
              <li v-if="IsLogged" class="nav-item ml-4">
                    {{CurrentUser.firstName}} {{CurrentUser.lastName}}
              </li>

              <li v-if="!IsLogged" class="nav-item">
                <router-link to="Login" class="btn btn-success">
                  <span class="material-icons mr-2">login</span>
                  Zaloguj
                </router-link>
                
              </li>

              <li v-if="IsLogged" class="nav-item dropdown">
                <a class="nav-link" href="javascript:;" id="navbarDropdownProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="material-icons">person</i>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">
                  <div class="dropdown-header">Profil uzytkownika</div>
                  <div class="dropdown-divider"></div>
                  <router-link :to="{name: 'UserEdit', params: {id: CurrentUser.id}}" class="dropdown-item">Edytuj profil</router-link>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#" v-on:click="SignOut">Wyloguj</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
</template>

<script>
import { signOut, getAuth } from "firebase/auth";
import UserModel from '../model/UserModel';

export default {
    name: "NavBar",
    components: {  },

    data(){
      return {
        IsLogged: false,
        CurrentUser: { lastName: '', firstName: '', id: '00' }
      }
    },

    created() {
      this.$parent.ProcessingIndicator = true;
      UserModel.GetLoggedUser( (usr)=> {
        this.CurrentUser = usr;  
        this.$root.user = usr;
        this.$parent.ProcessingIndicator = false;
        if (this.CurrentUser != null) {
          this.IsLogged = true
          if ( this.$route.redirectedFrom !== undefined )this.$router.push(this.$route.redirectedFrom.path);
        } else {
          this.IsLogged = false;
        }
      }, () => { 
          this.IsLogged = false; 
          this.$parent.ProcessingIndicator = false; 
        })      
    },

    methods:{
      SignOut: function() {
        const auth = getAuth();
        signOut(auth).then(() => {
          this.CurrentUser = { lastName: '', firstName: '', id: '00' }
          this.IsLogged = false
          UserModel.LoggedUser = { lastName: '', firstName: '', adminLvl: 0 }
          this.$router.push( { path: '/login' } ); 
        }).catch((error) => {
          console.log(error);
        });
      }
    },
    
    computed: {
      
    } 
}
</script>